/* You can add global styles to this file, and also import other style files */

@import "variable.css";


*{
  font-family: Arial, Helvetica, var(--bs-font-sans-serif) !important;
}
body {
  margin:0px;
  background-color:rgba(215, 215, 215, 1);
  background-image:none;
  position:relative;
  left:0px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  text-align:left;
}
.headerTextClass {
  font-family: "Arial-BoldMT", "Arial Bold", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: var(--header-font-size);
  color: var(--header-text-color);
}
.css-t7viak {
    background: rgb(255, 255, 255);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
}

.uitk-c-panel__content{
    overflow-y:unset;
  }
.uitk-c-form-field{
  display: flex ;
}

.uitk-c-component-load-indicator__spinner-container{

  background:white;

 }

 .uitk-c-component-load-indicator__container{

   display: inline;
   padding:0px;

 }
  .uitk-c-component-load-indicator__visible-text{

    width: 120%;
 }

 /*popover design took form bootstrap.min.css*/
.bs-popover-auto[x-placement^=bottom]>.arrow, .bs-popover-bottom>.arrow {
  top: calc(-.5rem - 1px);
}
.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
}
.popover, .popover .arrow {
  position: absolute;
  /* display: block; */
}
*, :after, :before {
  box-sizing: border-box;
}
.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  margin-top: .5rem;
}

.bs-popover-auto[x-placement^=bottom]>.arrow:after, .bs-popover-bottom>.arrow:after {
  top: 1px;
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
}
.popover .arrow:after, .popover .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^=bottom]>.arrow:before, .bs-popover-bottom>.arrow:before {
  top: 0;
  border-width: 0 .5rem .5rem;
  border-bottom-color: rgba(0,0,0,.25);
}

ngb-popover-window.bs-popover-bottom>.arrow, ngb-popover-window.bs-popover-top>.arrow {
  left: 50%;
  margin-left: -.5rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.uitk-c-radio__label{

  width: max-content;
}

.bg-darkgrey {
  background-color: rgb(86, 86, 86);
}

.bg-lightgrey {
  background-color: rgb(173, 173, 173);
}

.bg-orange {
  background-color: rgb(194, 86, 8);
  ;
}

.color-orange {
  color: rgb(194, 86, 8);
}

.link {
  cursor: pointer;
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}

.borderline {
  background-color: #002677;
  height: 51px;
  width: 4px;
  margin-right: 10px;
}

.highlight {
  color: red !important;
  background-color: yellow !important;
  font-weight: bold !important;
}

input {
  color: black !important;
}

select {
  color: black !important;
}

div {
  color: black !important;
}
.overlayForPopups {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128,128,128,0.5);
  z-index: 2000;
}
.swal2-shown {
  overflow: auto;
}
.swal2-container{
  z-index: 2000;
}
[aria-describedby="swal2-html-container"]:hover{
  opacity: 0.5;
}
.cdk-drag {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: relative;
}
.uitk-u-overlay{
  background: none;
}
.swal2-styled.swal2-confirm {
  background-color: #002677;
  
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px lightsalmon;
}
.uitk-c-dialog__header{
  cursor: move;
}
